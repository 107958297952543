import M$plugin$Modal from './facade/js/modal';
import M$control$ModalControl from './facade/js/modalcontrol';
import M$impl$control$ModalControl from './impl/ol/js/modalcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.Modal = M$plugin$Modal;
window.M.control.ModalControl = M$control$ModalControl;
window.M.impl.control.ModalControl = M$impl$control$ModalControl;
